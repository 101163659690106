export default {
  /*
   * 这边字母我已经都分好了，注意看注释，我排了一部分，后续我再补排上，目前没有全部排序完成，你们把新添加的写到对应字母下边即可
   * 注意每个字母下边排序也有顺序，先大写，后小写，再字符串
   * 数字排序也要按按顺序，0-9
   */
  // 数字
  '1 Hour': '1 小时',
  '15 Minutes': '15 分钟',
  '2 Hours': '2 小时',
  '30 Minutes': '30 分钟',
  '5 Minutes': '5 分钟',
  '6 Hours': '6 小时',
  // A
  Admin: '管理员',
  Audit: '审核',
  Alarms: '警告',
  Amount: '数量',
  Account: '账号',
  Administrator: '管理员',
  acceptableRange: '可收款金额范围',
  auditDetails: '审计详情',
  All: '总览',
  APIRequestUUID: 'API请求UUID',
  Attributes: '属性',
  APIResponseUUID: 'API响应UUID',
  Article: '文章',
  acceptRange: '可接受范围',
  apiName: 'api名称',
  amount: '数量',
  account: '账户',
  alert: '警告',
  announcement: '通告',
  AlarmStatistics: '警告统计',
  AlarmDistribution: '警告分布',
  'Associated Alarms': '关联警告',
  'Are you sure you want to perform this operation?':'是否确认执行该操作?',
  'Associated Alarm-Receivers': '关联警报接收者',
  'Associated Alarm-Messages': '关联警报信息',
  'Are you sure you want to unbind this user?': '你确定要解除此用户吗',
  'Alarm Status': '警告状态',
  'Alarm Name': '警告名称',
  'Alarm Level': '警告等级',
  'Add attributes': '添加属性',
  'Advanced settings': '高级设置',
  'Alarm Type': '警告类型',
  'After Amount': '之后数量',
  'Associated User Order': '关联用户订单',
  'Associated Merchant Order': '关联商户订单',
  'After successful payment, the order status will pending change from success':
    '支付成功后，订单状态将自动由 Pending 变为 Success',
  'Are you sure you want to remove this accessKey?': '确定要删除此访问权限？',
  'Are you sure you want to trigger a callback for this order?':
    '“您确定要为此订单触发回调吗?”',

  // B
  Bank: '银行',
  Base: '基本信息',
  Back: '返回',
  baned: '封禁',
  banner: '标语',
  basicInformation: '基本信息',
  bank: '银行',
  BasicInformation: '基本信息',
  // C
  Count: '数量',
  Currency: '货币',
  Config: '配置信息',
  Content: '内容',
  channel: '渠道',
  Customize: '自定义',
  Code: '代码',
  createdAt: '创建于',
  completedAt: '完成于',
  contentMessage: '消息',
  cover: '封面',
  code: '代码',
  collection: '收集',
  callbackStatus: '回调状态',
  channelUsers: '渠道用户',
  content: '评论',
  changeShareType: '修改共享模式',
  create: '创建',
  currency: '货币',
  CustomerService: '客服',
  ConfigInformation: '配置信息',
  ConfiderId: '委托者编号',
  CallbackStatus: '回调状态',
  CallbackStatusCode: '回调状态码',
  'Basic settings': '基本设置',
  'Bind User': '绑定用户',
  'Before Amount': '之前的数量',
  'Bank Account': '银行账号',
  'Change Parent': '修改父用户',
  'Channel users': '渠道用户',
  'Confider Name': '信任名称',
  'Confirmed At': '核实于',
  'Create User': '创建用户',
  'Change the bound user': '更改绑定用户',
  "Click 'YES' to complete the operation": '点击YES完成此操作',
  'Change Type': '修改用户类型',
  'Change Structure': '修改用户组织',
  'Change Password': '修改密码',
  'Confirm Password': '确认密码',
  'Change TransactionId': '修改交易编号',
  'Change order status': '修改订单状态',
  'Change User Group': '修改用户组',
  'Change Integral': '修改积分',
  'Create Merchant Order': '创建商户订单',
  'Create a new user order': '新建用户订单',
  'Change order externalId': '修改订单外部编号',
  'Change user order externalId': '修改订单交易编号',
  'Change orderTransactionId': '修改交易编号',
  // D
  Description: '介绍',
  Delete: '删除',
  Direction: '方向',
  Disable: '禁用',
  description: '描述',
  delete: '删除',
  disable: '禁用',
  disabled: '警告',
  direction: '方向',
  discoveredAt: '发生于',
  DeletedAt: '删除时间',
  'Delete User': '删除用户',
  'Delete Message': '删除消息',
  'Delete Article': '删除文章',
  'Delete UserGroup': '删除用户组',
  'Direct Members': '直接成员',
  // E
  EventAudit: '审计',
  Enable: '启用',
  eventAudit: '审计',
  Even: '事件',
  Edit: '编辑',
  Emergent: '紧急',
  Event: '事件',
  enable: '启用',
  email: '邮箱',
  eventTransaction: '交易',
  edit: '编辑',
  ExternalId: '外部编号',
  externalId: '外部编号',
  ExternalOrderId: '外部订单编号',
  'Edit Status': '编辑状态',
  'Edit Range': '编辑范围',
  'External order identifier, only used for merchants to identify orders themselves.':
    '外部订单识别码，仅用于商户识别订单本身。',
  'Edit user order status': '编辑订单状态',
  'Endpoint Type': '端点类型',
  'External Id': '外部编号',
  'Edit User Order': '编辑用户订单',
  'Edit AccessKey status': '修改AccessKey状态',
  'Edit Information': '编辑信息',
  // F
  fail: '失败',
  Fail: '已失败',
  failed: '失败',
  // G
  Global: '全局',
  Group: '所属组',
  'Generate AccessKey': '生成AccessKey',
  'Global-Setting': '全局设置',
  // H
  Hours: '小时',
  'Historical information': '历史信息',
  HistoricalInformation: '历史信息',
  // I
  Input: '输入',
  ID: '编号',
  Integral: '积分',
  INR: '卢比',
  Info: '信息',
  Income: '收入',
  isfc: 'ISFC',
  integralStatus: '积分状态',
  integralAmount: '积分数目',
  'INR Amount': '金额',
  'Integral History': '支付记录',
  'Integral Amount': '积分变更数值',
  'If this UPI does not belong to your wallet, a new wallet will be created and the UPI will be set to the one you entered':
    '如果此 UPI 不属于您的钱包，将创建一个新钱包，并将 UPI 设置为您输入的那个',
  'If you choose this option,the modification of this status will not be called back to the upstream service.':
    '如果选择此选项，则此状态的修改将不会回调回上游服务。',
  'If the callback status code is 200 or 201, the callback is complete.':
    '回调状态码是200或201则代表回调完成。',
  'If you select more than one user group, the user type will no longer be set automatically':
    '如果选择多个用户组，将不再自动设置用户类型。',
  // J
  // K
  // L
  lastSwitchedAt: '最近登录时间',
  lastOperator: '最近一次的操作者',
  lastOperatorExplanation: '最近操作者的解释',
  level: '水平',
  LastOperator: '上次操作者',
  LastOperatorExplanation: '上次操作者解释',
  LastSwitchedAt: '上次切换时间',
  'Last Switch Owner At': '上次更改订单所有者',
  'Last Operator': '上次操作员',
  'Log Status': '交易日志记录',
  'Last Operator Explanation': '上次操作人信息',
  // M
  Modify: '修改策略',
  Major: '主要',
  Merchant: '商户',
  moveWalletsPriority: '取消优先级',
  MerchantOrder: '商户订单',
  Minutes: '分钟',
  modify: '修改',
  merchantOrder: '商户订单',
  'Modify basic information': '编辑基本信息',
  'Modify Article': '修改文章内容',
  'Modify massage': '修改消息内容',
  'Modify shareType': '修改共享类型',
  'Modify Status': '编辑状态',
  'Merchant Order Edit': '商户订单编辑',
  'Merchant Order Status': '商户订单状态',
  // N
  Name: '名称',
  None: '不存在',
  Normal: '正常',
  none: '无',
  normal: '常规',
  name: '名称',
  // O
  Overview: '总览',
  OrderTransactionId: '交易编号',
  Organization: '组织',
  orderAmount: '订单数量',
  Outcome: '支出',
  orderIntegralAmount: '必须支付金额',
  owner: '作者',
  operator: '操作者',
  Operator: '操作者',
  Online: '在线',
  Offline: '离线',
  OrderIntegralAmount: '订单积分数',
  'Operator Explanation': '操作者解释',
  Owner: '拥有者',
  'Order Name': '订单名',
  'Operation Explanation': '操作说明',
  'Order Amount': '订单金额',
  'Order Integral Amount': '订单积分数',
  'Number Of Changes': '更改值',
  // P
  Password: '密码',
  Phone: '电话号',
  Policies: '策略',
  Parent: '父用户',
  Project: '项目',
  Paying: '支付中',
  PaymentWallet: '支付钱包',
  Pending: '等待中',
  public: '公开',
  payment: '支付',
  parent: '父用户',
  password: '密码',
  phone: '电话',
  pending: '等待中',
  'Payment wallet UPI': '付款钱包UPI',
  'Payment wallet information': '付款钱包信息',
  'Payment Code': '支付代码',
  'please select': '请选择',
  'please enter': '请输入',
  'Please Pay': '请付款',
  'Parent Group': '父用户组',
  'Payment wallet address': '支付钱包地址',
  'Please scan the QR code for payment': '请扫描二维码支付',
  'Please make sure that the password is always used twice':
    '请确保两次密码一致',
  // Q
  'Quota settings': '配额设置',
  'Quantity Before Change': '变更前数值',
  'Quantity After Change': '变更后数值',
  // R
  rate: '成功率',
  Resource: '资源',
  ResourceId: '资源编号',
  Returned: '返回',
  resetWalletsPriority: '设置优先级',
  resourceType: '资源类型',
  request: '请求',
  response: '响应',
  review: '审查',
  Review: '审查',
  receiver: '接收者',
  Reason: '原因',
  'Role Type': '角色类型',
  'Resume Changer': '修改静默时间',
  'resumed At': '恢复时间',
  'Related UPI': '收款地址',
  'Receiving wallet UPI': '收款钱包UPI',
  'Related Orders': '关联订单',
  'Related Messages': '关联消息',
  'Receiving wallet information': '收款钱包信息',
  'Recharge Within One Week': '子用户一周内充值',
  'Recharge Within One Month': '子用户一月内充值',
  'Read Only': '只读',
  // S
  Select: '选择',
  Status: '状态',
  Secret: '密码',
  Success: '已成功',
  Special: '特殊',
  Sender: '发送者',
  Structure: '组织',
  System: '系统',
  State: '状态',
  SetRole: '修改组',
  shareType: '共享方式',
  status: '状态',
  sender: '发送者',
  success: '已成功',
  special: '特殊',
  ShareType: '共享方式',
  'Set silence period': '设置静默时间',
  'search organization': '搜索组织',
  'Skip Callback': '跳过回调',
  'Structure Edit': '编辑组织',
  'Send status change notification': '发送状态更改通知',
  'Share Type': '共享方式',
  'Save or send the AccessKey pair to the user. If you lose the AccessKey pair, you can create a new one.':
    '保存或发送 AccessKey 给用户。如果丢失了 AccessKey，您可以创建新的 AccessKey。',
  // T
  Type: '类型',
  Total: '全部',

  Tag: '标签',
  timeConsumed: '消耗时间',
  taskResult: '任务结果',
  type: '类型',
  title: '标题',
  TransactionAmount: '交易金额',
  TabAssociateUserWallet: '关联用户钱包',
  TransactionId: '交易编号',
  TransactionBlockId: '交易区块编号',
  TransactionFrom: '交易来源',
  TransactionTo: '交易目的地',
  TransactionInformation: '交易信息',
  TriggerCallback: '触发回调',
  'Trigger Callback': '触发回调',
  'Transaction From Address': '交易发件人地址',
  'Transaction To Address': '交易地址',
  'Transaction Amount': '交易金额',
  'Transaction Block Id': '交易块 ID',
  // U
  User: '用户',
  Unit: '单位',
  UnConfirm: '不确定',
  UserOrder: '用户订单',
  UserWallet: '用户钱包',
  UserName: '用户名',
  userUUID: '用户UUID',
  user: '用户',
  userWallet: '用户钱包',
  userId: '用户编号',
  userName: '用户名',
  UserWalletId: '用户钱包编号',
  UserId: '用户编号',
  userMessageCategoryId: '用户消息类别ID',
  UserWalletAttributes: '用户钱包属性',
  'Unbind user order': '解除绑定用户',
  'UserWallet Name': '用户钱包名',
  'User Name': '用户名',
  'User Id': '用户 ID',
  'User Edit': '编辑用户',
  'User Wallet': '用户钱包',
  'User Type': '用户类型',
  'User Detail': '用户性情',
  'User Group Name': '组名',
  'User Order Status': '用户订单状态',
  'User Group Edit': '编辑用户组',
  'User Group Policies Edit': '组策略修改',
  'userWallet Type': '用户钱包类型',
  "Used to store a user's WebAuthn certificate number or AccessKey":
    '用于存储用户 WebAuthn 证书编号或 AccessKey',
  // V
  Version: '版本',
  version: '版本',
  // W
  Wallet: '渠道',
  Worker: '员工',
  walletId: '钱包ID',
  walletName: '钱包名',
  walletType: '钱包类型',
  'When the status changes, the corresponding integral amount of the record will also be refunded or deducted again.':
    '当状态发生变化时，记录的相应积分金额也将再次退还或扣除。',
  'When you modify a user group, the user type is automatically set.':
    '当修改用户组的时候会自动设置用户类型。',
  // X
  // Y
  // Z
};
