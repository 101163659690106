import { Generator, Logger } from '@-/oxygen';
import { Notification } from '@/components/GlobalNotification';

setTimeout(() => {
  // 输出产品信息
  Logger.capsule(
    'Two Factor Technology > Forest > Mangrove',
    'Build20241001.JSX',
  );
}, 0);

const { getInitialState, layout, locale, request, rootContainer } =
  Generator.forApp({
    layout: () => ({
      actionsRender: () => [<Notification key="notification" />],
      menuRender: null,
      menu: {
        locale: true,
        type: 'group',
        collapsedShowGroupTitle: true,
      },
    }),
  });

export { getInitialState, layout, locale, request, rootContainer };
